// Simpler version of the original coveApi.js file (no recompose)

import {
  Customer,
  Quote,
  Coverage,
  Item,
  Vehicle,
  Token,
  PartnerQuote,
  PartnerPetQuote,
  Payment,
  PaymentMethod,
  Pulse,
  Media,
  Auth
} from '@covexl/cove-api-client';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const coveApi = {
  Customer,
  Quote,
  Coverage,
  Item,
  Vehicle,
  Token,
  PartnerQuote,
  PartnerPetQuote,
  Payment,
  PaymentMethod,
  Pulse,
  Media,
  Auth
};

export default apiName => {
  return new coveApi[apiName](publicRuntimeConfig.API_HOST, publicRuntimeConfig.ENVIRONMENT);
};
